import React from "react";
import Navbar from "../components/Navbar.tsx";
import Footer from "../components/Footer.tsx";

export default function Normativa() {
  return (
    <div>
      <Navbar transparent />
      <div className="h-16 bg-neutral-950">
        {/* Contenuto del rettangolo */}
      </div>
      <div className="h-1.5 bg-neutral-950">
        {/* Contenuto del rettangolo */}
      </div>
      <div className="py-4 max-w-screen-lg mx-auto">
        <div className="text-center mb-14">
          <div className="text-3xl sm:text-4xl leading-normal font-extrabold tracking-tight text-gray-900">
            Normativa <span className="text-yellow-400">Italiana</span>
          </div>
        </div>
        <div className="mb-16">
          <div className="text-3xl font-extrabold text-gray-900 mb-4">
            <span className="text-yellow-400">Requisiti per l’acquisto</span>
          </div>
          <p className="text-lg font-normal text-gray-800 mb-4">
            Il D.Lgs. 123/2015, art. 5 fissa delle limitazioni all’acquisto dei
            prodotti pirotecnici.
          </p>
          <p className="text-lg font-normal text-gray-800 mb-1">
            In particolare esistono queste categorie per l'acquisto:
          </p>
          <ul className="list-disc pl-8">
            <li className="text-lg font-normal text-gray-800 mb-1">
              <p className="inline underline">Categoria F1:</p> l’acquisto è
              consentito a privati che abbiano compiuto almeno il 14° anno di
              età;
            </li>
            <li className="text-lg font-normal text-gray-800 mb-1">
              <p className="inline underline">Categoria F2:</p> l’acquisto è
              consentito a privati che abbiano compiuto almeno il 18° anno di
              età ed esibiscano il documento di identità in corso di validità
            </li>
            <li className="text-lg font-normal text-gray-800 mb-1">
              <p className="inline underline">Categoria F3:</p> l’acquisto è
              consentito a privati che abbiano compiuto almeno il 18° anno di
              età e che siano in possesso di porto d’armi o di nulla osta
              rilasciato dal questore
            </li>
            <li className="text-lg font-normal text-gray-800 mb-1">
              <p className="inline underline">Categoria T1:</p> l’acquisto è
              consentito a privati che abbiano compiuto almeno il 18° anno di
              età ed esibiscano il documento di identità in corso di validità
            </li>
            <li className="text-lg font-normal text-gray-800 mb-1">
              <p className="inline underline">Categoria P1:</p> l’acquisto è
              consentito a privati che abbiano compiuto almeno il 18° anno di
              età ed esibiscano il documento di identità in corso di validità
            </li>
          </ul>

          <div className="text-3xl font-extrabold text-gray-900 mt-8">
            <span className="text-yellow-400">Requisiti di utilizzo</span>
            <p className="text-lg font-normal text-gray-800 mt-4">
              Lo stesso D.Lgs. 123/2015, fissa delle distanze minime di
              sicurezza per ogni categoria di prodotto pirotecnico.
            </p>
            <p className="text-lg font-normal text-gray-800 mt-4">
              In particolare:
            </p>
            <ul className="list-disc pl-8">
              <li className="text-lg font-normal text-gray-800 mb-1">
                <p className="inline underline">Categoria F1:</p> distanza
                minima da rispettare è di 1 metro
              </li>
              <li className="text-lg font-normal text-gray-800 mb-1">
                <p className="inline underline">Categoria F2:</p> distanza
                minima da rispettare è di 8 metri
              </li>
              <li className="text-lg font-normal text-gray-800 mb-1">
                <p className="inline underline">Categoria F3:</p> distanza
                minima da rispettare è di 15 metri
              </li>
              <li className="text-lg font-normal text-gray-800 mb-1">
                <p className="inline underline">Categoria T1:</p> distanza
                minima da rispettare è di 8 metri
              </li>
              <li className="text-lg font-normal text-gray-800 mb-1">
                <p className="inline underline">Categoria P1:</p> distanza
                minima da rispettare è di 8 metri
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
