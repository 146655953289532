import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { RoutesDef } from "../components/RoutesDef.tsx";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const logo = require("../static/img/official-logo.png");

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const auth = async () => {
    try {
      const response = await axios.post(
        "https://irazzistoremodena.it/api/authenticate",
        {
          username,
          password,
        }
      );
      console.log(response.data);
      if (response.data.status) {
        // imposto il cookie
        toast.success("Login effettuato con successo!");
        document.cookie = `token=${response.data.token}`;
        window.location.href = RoutesDef.CONTROL;
      } else {
        toast.error("Username o password errati!");
      }
    } catch (error) {
      console.log(error);
      toast.error("Errore durante il login!");
    }
  };

  const checkCookie = async () => {
    try {
      const response = await axios.get(
        "https://irazzistoremodena.it/api/checkCookie",
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${document.cookie.split("=")[1]}`
          }
        }
      );
      console.log(response.data);
      if (response.data.status) {
        window.location.href = RoutesDef.CONTROL;
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkCookie();
  }, []);

  const handleLogin = async () => {
    await auth();
  }

  return (
    <div className="h-screen flex flex-col md:flex-row justify-center space-y-10 md:space-y-0 md:space-x-16 items-center my-2 mx-5 md:mx-0 md:my-0">
      <Toaster
        position="top-right"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          // Default options for specific types
          success: {
            duration: 3000,
            style: {
              background: "#424242",
              color: "#fff",
            },
          },
        }}
      />
      <div className="md:w-1/3 max-w-sm">
        <img
          src={logo}
          alt="IRSM-logo"
          className="max-w-xs mx-auto md:mx-0 md:ml-0 md:mr-auto"
        />
      </div>
      <div className="md:w-1/3 max-w-sm">
        <div className="my-5 flex items-center before:mt-0.5 before:flex-1 before:border-t before:border-neutral-300 after:mt-0.5 after:flex-1 after:border-t after:border-neutral-300">
          <p className="mx-4 mb-0 text-center font-semibold text-slate-500">
            ACCEDI
          </p>
        </div>
        <input
          className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded"
          type="text"
          placeholder="Email Address"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded mt-4"
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <div className="text-center md:text-left">
          <button
            className="mt-4 bg-blue-600 hover:bg-blue-700 px-4 py-2 text-white uppercase rounded text-xs tracking-wider"
            type="submit"
            onClick={handleLogin}
          >
            Login
          </button>
        </div>
        <div className="mt-4 font-semibold text-sm text-slate-500 text-center md:text-left">
          Finito qui per sbaglio?{" "}
          <Link
            className="text-blue-700 hover:underline hover:underline-offset-4"
            to={RoutesDef.HOME}
          >
            Torna indietro
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
