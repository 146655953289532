import React from 'react';

export default function SimpleFooter() {
  return (
    <footer className="relative bg-gray-300 pb-6">
      
      <div className="container mx-auto px-4">
        <hr className="my-6 border-gray-400" />
        <div className="flex flex-col md:flex-row items-center justify-center md:justify-between">
          <div className="md:mx-auto px-4 text-center md:text-left">
            {" "}
            <div className="text-sm text-gray-600 font-semibold py-1 lg:ml-28">
              Copyright © {new Date().getFullYear()} I Razzi Store Modena.
            </div>
          </div>
          <div className="flex flex-col md:flex-row items-center justify-center md:justify-between">
            <div className="text-sm text-gray-600 font-semibold py-1">
              Developed by{" "}
              <a
                href="https://www.tommasoceredi.it"
                target="_blank"
                rel="noreferrer"
                className="text-gray-600 hover:text-gray-900"
              >
                tommasoceredi.it
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
