import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";

import Navbar from "../components/Navbar.tsx";
import Footer from "../components/Footer.tsx";

library.add(faQuestion);

export default function Faq() {
  return (
    <div>
      <Navbar transparent />
      <div className="h-16 bg-neutral-950">
        {/* Contenuto del rettangolo */}
      </div>
      <div className="h-1.5 bg-neutral-950">
        {/* Contenuto del rettangolo */}
      </div>
      <div className="py-4 max-w-screen-lg mx-auto">
        <div className="text-center mb-16">
          <p className="-mb-2 mt-4 text-sm leading-7 text-gray-500 font-regular">
            Frequently Asked Questions
          </p>
          <h3 className="text-3xl sm:text-4xl leading-normal font-extrabold tracking-tight text-gray-900">
            Domande <span className="text-yellow-400">Frequenti</span>
          </h3>
        </div>

        <div className="px-10 sm:px-16 sm:flex items-start mb-10">
          <h3 className="py-3 font-bold text-lg text-gray-900 w-3/12">
            Prodotti
          </h3>
          <div className="w-9/12">
            <div className="flex items-start mb-8">
              <div className="hidden sm:flex items-center justify-center p-3 mr-3 rounded-full bg-black text-yellow-300 border-4 border-white">
                <svg
                  width="24px"
                  fill="white"
                  height="24px"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g data-name="Layer 2">
                    <g data-name="menu-arrow">
                      <rect
                        width="24"
                        height="24"
                        transform="rotate(180 12 12)"
                        opacity="0"
                      ></rect>
                      <FontAwesomeIcon icon={faQuestion} />
                    </g>
                  </g>
                </svg>
              </div>
              <div className="text-md">
                <h1 className="text-gray-900 font-semibold mb-2">
                  Se accendo la miccia ma il petardo non esplode, posso
                  riaccenderlo?
                </h1>
                <p className="text-gray-500 text-sm">
                  Assolutamente no! Se un petardo non esplode, non bisogna mai
                  riaccenderlo. In caso di malfunzionamento, è necessario
                  attendere almeno 15 minuti prima di avvicinarsi al petardo e
                  procedere con la sua rimozione.
                </p>
              </div>
            </div>
            <div className="flex items-start mb-8">
              <div className="hidden sm:flex items-center justify-center p-3 mr-3 rounded-full bg-black text-yellow-300 border-4 border-white text-xl font-semibold">
                <svg
                  width="24px"
                  fill="white"
                  height="24px"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g data-name="Layer 2">
                    <g data-name="menu-arrow">
                      <rect
                        width="24"
                        height="24"
                        transform="rotate(180 12 12)"
                        opacity="0"
                      ></rect>
                      <FontAwesomeIcon icon={faQuestion} />
                    </g>
                  </g>
                </svg>
              </div>
              <div className="text-md">
                <h1 className="text-gray-900 font-semibold mb-2">
                  Come posso sapere se un petardo è difettoso?
                </h1>
                <p className="text-gray-500 text-sm">
                  Una volta aperta la confezione, verificare lo stato del
                  prodotto. Se il petardo presenta segni di danneggiamento o
                  parti di esso si sono staccate, non utilizzarlo e procedere
                  con la sua rimozione.
                </p>
              </div>
            </div>
            <div className="flex items-start mb-8">
              <div className="hidden sm:flex items-center justify-center p-3 mr-3 rounded-full bg-black text-yellow-300 border-4 border-white text-xl font-semibold">
                <svg
                  width="24px"
                  fill="white"
                  height="24px"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g data-name="Layer 2">
                    <g data-name="menu-arrow">
                      <rect
                        width="24"
                        height="24"
                        transform="rotate(180 12 12)"
                        opacity="0"
                      ></rect>
                      <FontAwesomeIcon icon={faQuestion} />
                    </g>
                  </g>
                </svg>
              </div>
              <div className="text-md">
                <h1 className="text-gray-900 font-semibold mb-2">
                  Cosa faccio se un prodotto non funziona?
                </h1>
                <p className="text-gray-500 text-sm">
                  Se per esembio una batteria di fuochi d'artificio non funziona
                  (compresa la miccia di riserva se presente), non bisogna mai
                  tentare di riaccenderla. Riportatecela e vi verrà sostituita
                  con un prodotto funzionante.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="px-10 sm:px-16 sm:flex items-start mb-10">
          <h3 className="py-3 font-bold text-lg text-gray-900 w-3/12">
            Vendita
          </h3>
          <div className="w-9/12">
            <div className="flex items-start mb-8">
              <div className="hidden sm:flex items-center justify-center p-3 mr-3 rounded-full bg-black text-yellow-300 border-4 border-white text-xl font-semibold">
                <svg
                  width="24px"
                  fill="white"
                  height="24px"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g data-name="Layer 2">
                    <g data-name="menu-arrow">
                      <rect
                        width="24"
                        height="24"
                        transform="rotate(180 12 12)"
                        opacity="0"
                      ></rect>
                      <FontAwesomeIcon icon={faQuestion} />
                    </g>
                  </g>
                </svg>
              </div>
              <div className="text-md">
                <h1 className="text-gray-900 font-semibold mb-2">
                  E' consentita la vendita di fuochi d'artificio a minorenni?
                </h1>
                <p className="text-gray-500 text-sm">
                  No, la vendita di fuochi d'artificio è consentita solo a
                  maggiorenni. La legge vieta la vendita di fuochi d'artificio a
                  persone di età inferiore ai 18 anni.
                </p>
              </div>
            </div>
            <div className="flex items-start mb-8">
              <div className="hidden sm:flex items-center justify-center p-3 mr-3 rounded-full bg-black text-yellow-300 border-4 border-white text-xl font-semibold">
                <svg
                  width="24px"
                  fill="white"
                  height="24px"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g data-name="Layer 2">
                    <g data-name="menu-arrow">
                      <rect
                        width="24"
                        height="24"
                        transform="rotate(180 12 12)"
                        opacity="0"
                      ></rect>
                      <FontAwesomeIcon icon={faQuestion} />
                    </g>
                  </g>
                </svg>
              </div>
              <div className="text-md">
                <h1 className="text-gray-900 font-semibold mb-2">
                  Cosa posso comprare se sono minorenne?
                </h1>
                <p className="text-gray-500 text-sm">
                  La legge vieta la vendita di fuochi d'artificio a persone di
                  età inferiore ai 18 anni a meno che non siano accompagnati da
                  un maggiorenne.
                </p>
              </div>
            </div>
            <div className="flex items-start mb-8">
              <div className="hidden sm:flex items-center justify-center p-3 mr-3 rounded-full bg-black text-yellow-300 border-4 border-white text-xl font-semibold">
                <svg
                  width="24px"
                  fill="white"
                  height="24px"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g data-name="Layer 2">
                    <g data-name="menu-arrow">
                      <rect
                        width="24"
                        height="24"
                        transform="rotate(180 12 12)"
                        opacity="0"
                      ></rect>
                      <FontAwesomeIcon icon={faQuestion} />
                    </g>
                  </g>
                </svg>
              </div>
              <div className="text-md">
                <h1 className="text-gray-900 font-semibold mb-2">
                  Se ho bisogno di fare uno spettacolo pirotecnico, ma il
                  negozio è chiuso, cosa posso fare?
                </h1>
                <p className="text-gray-500 text-sm">
                  Se hai bisogno di fare uno spettacolo pirotecnico, ma il
                  negozio è chiuso, puoi contattarci e saremo lieti di fissare
                  un appuntamento per te.
                </p>
              </div>
            </div>
            <div className="flex items-start mb-8">
              <div className="hidden sm:flex items-center justify-center p-3 mr-3 rounded-full bg-black text-yellow-300 border-4 border-white text-xl font-semibold">
                <svg
                  width="24px"
                  fill="white"
                  height="24px"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g data-name="Layer 2">
                    <g data-name="menu-arrow">
                      <rect
                        width="24"
                        height="24"
                        transform="rotate(180 12 12)"
                        opacity="0"
                      ></rect>
                      <FontAwesomeIcon icon={faQuestion} />
                    </g>
                  </g>
                </svg>
              </div>
              <div className="text-md">
                <h1 className="text-gray-900 font-semibold mb-2">
                  E' possibile fare ordini online?
                </h1>
                <p className="text-gray-500 text-sm">
                  La normativa italiana vieta la vendita di fuochi d'artificio
                  online. L'art 47 TULPS specifica che la vendita di fuochi
                  d'artificio è consentita solo in negozi fisici. Diffidate da
                  chi vi propone la vendita online!
                </p>
              </div>
            </div>
            <div className="flex items-start mb-8">
              <div className="hidden sm:flex items-center justify-center p-3 mr-3 rounded-full bg-black text-yellow-300 border-4 border-white text-xl font-semibold">
                <svg
                  width="24px"
                  fill="white"
                  height="24px"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g data-name="Layer 2">
                    <g data-name="menu-arrow">
                      <rect
                        width="24"
                        height="24"
                        transform="rotate(180 12 12)"
                        opacity="0"
                      ></rect>
                      <FontAwesomeIcon icon={faQuestion} />
                    </g>
                  </g>
                </svg>
              </div>
              <div className="text-md">
                <h1 className="text-gray-900 font-semibold mb-2">
                  E' possibile acquistare accendini o fiammiferi?
                </h1>
                <p className="text-gray-500 text-sm">
                  La vendita di accendini e fiammiferi non è consentita ai
                  negozi di fuochi d'artificio.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="px-10 sm:px-16 sm:flex items-start mb-10">
          <h3 className="py-3 font-bold text-lg text-gray-900 w-3/12">
            Restrizioni comunali
          </h3>
          <div className="w-9/12">
            <div className="flex items-start mb-8">
              <div className="hidden sm:flex items-center justify-center p-3 mr-3 rounded-full bg-black text-yellow-300 border-4 border-white text-xl font-semibold">
                <svg
                  width="24px"
                  fill="white"
                  height="24px"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g data-name="Layer 2">
                    <g data-name="menu-arrow">
                      <rect
                        width="24"
                        height="24"
                        transform="rotate(180 12 12)"
                        opacity="0"
                      ></rect>
                      <FontAwesomeIcon icon={faQuestion} />
                    </g>
                  </g>
                </svg>
              </div>
              <div className="text-md">
                <h1 className="text-gray-900 font-semibold mb-2">
                  Il mio comune ha vietato l'uso di fuochi d'artificio. Cosa
                  posso fare?
                </h1>
                <p className="text-gray-500 text-sm">
                  I prodotti acquistati presso il nostro negozio sono conformi e
                  certificati secondo le normative dell'Unione Europea (CE).
                  Pertanto, a meno di giustificazioni specifiche e
                  proporzionate, non possono essere vietati. <br /> Per maggiori
                  informazioni:{" "}
                  <a
                    href="https://www.to.camcom.it/guida-sicurezza-dei-prodotti-e-marcatura-ce"
                    className="underline"
                    target="_blank" rel="noreferrer"
                  >
                    clicca qui
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
