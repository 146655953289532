import React, { FC } from "react";
import { Sidebar, Menu, MenuItem, MenuItemStyles } from "react-pro-sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { faWeightHanging } from "@fortawesome/free-solid-svg-icons";
import { faChartColumn } from "@fortawesome/free-solid-svg-icons";
import { faClockRotateLeft } from "@fortawesome/free-solid-svg-icons";
//@ts-ignore
import { RoutesDef } from "../../components/RoutesDef.tsx";
import { Link } from "react-router-dom";

import { SidebarHeader } from "./SidebarHeader.tsx";
import { Typography } from "./Typography.tsx";

library.add(
  faBars,
  faRightFromBracket,
  faCalendarDays,
  faFile,
  faWeightHanging,
  faChartColumn,
  faClockRotateLeft
);

interface CustomSidebarProps {
  toggled: boolean;
  setToggled: (value: boolean) => void;
  setBroken: (value: boolean) => void;
}

const CustomSidebar: FC<CustomSidebarProps> = ({
  toggled,
  setToggled,
  setBroken,
}) => {
  const menuItemStyles: MenuItemStyles = {
    root: {
      fontSize: "13px",
      fontWeight: 400,
    },
    button: {
      '&:hover': {
        backgroundColor: "rgba(255, 255, 255, 0.1)",
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  return (
    <Sidebar
      toggled={toggled}
      onBackdropClick={() => setToggled(false)}
      onBreakPoint={setBroken}
      breakPoint="lg"
      rootStyles={{
        color: "#ffff",
      }}
      backgroundColor="#1F2A40"
      width="230px"
    >
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <SidebarHeader style={{ marginBottom: "24px", marginTop: "16px" }} />
        <div style={{ flex: 1, marginBottom: "32px" }}>
          <div style={{ padding: "0 24px", marginBottom: "8px" }}>
            <Typography
              variant="body2"
              fontWeight={600}
              style={{ letterSpacing: "0.5px" }}
              color="#fff"
            >
              Controllo
            </Typography>
          </div>
          <Menu menuItemStyles={menuItemStyles}>
            <Link to={RoutesDef.DASHBOARD}>
              <MenuItem icon={<FontAwesomeIcon icon={faChartColumn} />}>
                Dashboard
              </MenuItem>
            </Link>
            <Link to={RoutesDef.APERTURE}>
              <MenuItem icon={<FontAwesomeIcon icon={faCalendarDays} />}>
                {" "}
                Aperture
              </MenuItem>
            </Link>
            <MenuItem
              disabled
              icon={<FontAwesomeIcon icon={faClockRotateLeft} />}
            >
              {" "}
              Storico
            </MenuItem>
          </Menu>
          <div style={{ padding: "0 24px", marginBottom: "8px" }}>
            <Typography
              variant="body2"
              fontWeight={600}
              style={{ letterSpacing: "0.5px" }}
            >
              Magazzino
            </Typography>
          </div>
          <Menu menuItemStyles={menuItemStyles}>
            <MenuItem disabled icon={<FontAwesomeIcon icon={faFile} />}>
              {" "}
              Report
            </MenuItem>
            <MenuItem
              disabled
              icon={<FontAwesomeIcon icon={faWeightHanging} />}
            >
              {" "}
              Carico
            </MenuItem>
          </Menu>
        </div>
      </div>
    </Sidebar>
  );
};

export default CustomSidebar;
