import React, { useEffect, useState } from "react";
import axios from "axios";
import { RoutesDef } from "../../components/RoutesDef.tsx";
import CustomSidebar from "./CustomSidebar.tsx";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";

library.add(faBars, faRightFromBracket);

export default function Control() {
  const [toggled, setToggled] = useState(false);
  const [broken, setBroken] = useState(false);
  const [logged, setLogged] = useState(false);

  const checkCookie = async () => {
    try {
      const response = await axios.get(
        "https://irazzistoremodena.it/api/checkCookie",
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${document.cookie.split("=")[1]}`
          }
        }
      );
      if (response.data.status) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  useEffect(() => {
    const checkCookieAndSetLogged = async () => {
      if (await checkCookie()) {
        setLogged(true);
      } else {
        window.location.href = RoutesDef.HOME;
      }
    };

    checkCookieAndSetLogged();
  }, []);

  return logged ? (
    <div className="flex">
      <CustomSidebar
        toggled={toggled}
        setToggled={setToggled}
        setBroken={setBroken}
      />
      <main className="flex-grow">
        <div
          className="h-screen flex flex-col  "
          style={{ backgroundColor: "#141b2d" }}
        >
          <nav className="bg-white border-gray-200 dark:bg-gray-900 h-12">
            <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-2">
              <div className="flex items-center space-x-3 ">
                {broken && (
                  <button
                    type="button"
                    className="text-white font-medium text-sm p-2.5 text-center inline-flex items-center me-2"
                    onClick={() => setToggled(!toggled)}
                  >
                    <FontAwesomeIcon icon={faBars} />
                  </button>
                )}
                <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
                  I RAZZI STORE MODENA
                </span>
              </div>
              <div className="flex items-center space-x-3 ">
                <Link to={RoutesDef.HOME} className="text-white">
                  <FontAwesomeIcon icon={faRightFromBracket} />
                </Link>
              </div>
            </div>
          </nav>
        </div>
      </main>
    </div>
  ) : (
    <div style={{ backgroundColor: "#141b2d" }}></div>
  );
}
