import React from "react";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { faDribbble } from "@fortawesome/free-brands-svg-icons";
import { faFileLines } from "@fortawesome/free-regular-svg-icons";
import { faArrowAltCircleDown } from "@fortawesome/free-regular-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faPhoneVolume } from "@fortawesome/free-solid-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faStore } from "@fortawesome/free-solid-svg-icons";

library.add(
  faFacebookF,
  faXTwitter,
  faBars,
  faDribbble,
  faFileLines,
  faArrowAltCircleDown,
  faLocationDot,
  faInstagram,
  faPhoneVolume,
  faStore
);

const logo = require("../static/img/official-logo.png");

export default function Navbar(props: { transparent: any }) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const [isSticky, setSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setSticky(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <nav
      className={
        (isSticky || props.transparent
          ? "fixed top-0 z-50 w-full"
          : "absolute") +
        " flex flex-wrap items-center justify-between px-2 py-3 " +
        (isSticky ? "bg-neutral-950 shadow-lg" : "bg-transparent")
      }
    >
      <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
        <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
          <img
            src={logo}
            alt="I Razzi Store"
            className="h-10 w-15 inline-block mr-2 lg:mr-6"
          />

          <a
            className={
              (props.transparent ? "text-yellow-300" : "text-gray-800") +
              " text-lg font-bold leading-relaxed inline-block mr-2 py-2 whitespace-nowrap uppercase"
            }
            href="/"
          >
            I Razzi Store Modena
          </a>
          <button
            className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
            type="button"
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            <FontAwesomeIcon
              icon={faBars}
              className={props.transparent ? "text-white" : "text-gray-800"}
            />
          </button>
        </div>
        <div
          className={
            "lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none" +
            (navbarOpen ? " block rounded shadow-lg" : " hidden")
          }
          id="example-navbar-warning"
        >
          {/*<ul className="flex flex-col lg:flex-row list-none mr-auto">
            <li className="flex items-center">
              <a
                className={
                  (props.transparent
                    ? "lg:text-white lg:hover:text-gray-300 text-gray-800"
                    : "text-gray-800 hover:text-gray-600") +
                  " px-3 py-4 lg:py-2 flex items-center text-lg uppercase font-bold"
                }
                href="/"
              >
                <FontAwesomeIcon
                  icon={faStore}
                  className={
                    (props.transparent
                      ? "lg:text-gray-300 text-gray-500 lg:ml-4"
                      : "text-gray-500") + " text-lg leading-lg mr-2"
                  }
                />{" "}
                Catalogo
              </a>
            </li>
                </ul>*/}
          <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
            <li className="flex items-center">
              <a
                className={
                  (props.transparent
                    ? "lg:text-white lg:hover:text-gray-300 text-gray-800"
                    : "text-gray-800 hover:text-gray-600") +
                  " px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                }
                href="https://www.instagram.com/irazzistore_modena"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  className={
                    (props.transparent
                      ? "lg:text-gray-300 text-gray-500"
                      : "text-gray-500") + " text-lg leading-lg"
                  }
                />

                <span className="lg:hidden inline-block ml-2">Instagram</span>
              </a>
            </li>

            <li className="flex items-center">
              <a
                className={
                  (props.transparent
                    ? "lg:text-white lg:hover:text-gray-300 text-gray-800"
                    : "text-gray-800 hover:text-gray-600") +
                  " px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                }
                href="https://maps.app.goo.gl/2njQgFZDp8LT5t7H7"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  icon={faLocationDot}
                  className={
                    (props.transparent
                      ? "lg:text-gray-300 text-gray-500"
                      : "text-gray-500") + " text-lg leading-lg "
                  }
                />
                <span className="lg:hidden inline-block ml-2">Google Maps</span>
              </a>
            </li>

            <li className="flex items-center">
              <button
                className={
                  (props.transparent
                    ? "bg-yellow-300 text-gray-800 active:bg-gray-100"
                    : "bg-pink-500 text-white active:bg-pink-600") +
                  " text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3"
                }
                type="button"
                style={{ transition: "all .15s ease" }}
                onClick={() => {
                  window.location.href = "tel:+393343947875";
                }}
              >
                CHIAMA&nbsp;
                <FontAwesomeIcon icon={faPhoneVolume} />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
