import React from "react";
import { Link } from "react-router-dom";
import { RoutesDef } from "./RoutesDef.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faPhoneVolume } from "@fortawesome/free-solid-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faHourglass } from "@fortawesome/free-solid-svg-icons";

library.add(faEnvelope, faInstagram, faPhoneVolume, faLocationDot, faHourglass);

export default function Footer() {
  return (
    <footer className="relative bg-gray-300 pt-8 pb-6">
      <div
        className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style={{ height: "80px" }}
      >
        <svg
          className="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            className="text-gray-300 fill-current"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap">
          <div className="w-full lg:w-6/12 px-4">
            <h4 className="text-3xl font-semibold">Rimaniamo in contatto!</h4>
            <h5 className="text-lg mt-0 mb-2 text-gray-700">
              Trovaci su una qualsiasi di queste piattaforme, rispondiamo entro
              1-2 giorni lavorativi.
            </h5>
            <div className="mt-6 flex">
              <button
                className="bg-white text-yellow-300 shadow-lg font-normal h-10 w-10 flex items-center justify-center rounded-full outline-none focus:outline-none mr-2 p-3"
                type="button"
                onClick={() => {
                  window.open("mailto:info@irazzistoremodena.it");
                }}
              >
                <FontAwesomeIcon icon={faEnvelope} size="lg" />
              </button>
              <button
                className="bg-white text-yellow-400 shadow-lg font-normal h-10 w-10 flex items-center justify-center rounded-full outline-none focus:outline-none mr-2 p-3"
                type="button"
                onClick={() => {
                  window.open("https://www.instagram.com/irazzistore_modena/");
                }}
              >
                <FontAwesomeIcon icon={faInstagram} size="lg" />
              </button>
              <button
                className="bg-white text-yellow-500 shadow-lg font-normal h-10 w-10 flex items-center justify-center rounded-full outline-none focus:outline-none mr-2 p-3"
                type="button"
                onClick={() => {
                  window.open("tel:+393343947875");
                }}
              >
                <FontAwesomeIcon icon={faPhoneVolume} size="lg" />
              </button>
              <button
                className="bg-white text-yellow-600 shadow-lg font-normal h-10 w-10 flex items-center justify-center rounded-full outline-none focus:outline-none mr-2 p-3"
                type="button"
                onClick={() => {
                  window.open("https://maps.app.goo.gl/2njQgFZDp8LT5t7H7");
                }}
              >
                <FontAwesomeIcon icon={faLocationDot} size="lg" />
              </button>
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="flex flex-wrap items-top mb-6">
              <div className="w-full lg:w-4/12 px-4 ml-auto mt-6 lg:mt-0">
                <span className="block uppercase text-gray-600 text-sm font-semibold mb-2">
                  Link Utili
                </span>
                <ul className="list-unstyled">
                  <li>
                    <Link
                      className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                      to={RoutesDef.FAQ}
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                    >
                      Faq
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                      to={RoutesDef.NORMATIVA}
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                    >
                      Normativa
                    </Link>
                  </li>
                  <li>
                    <div className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm cursor-not-allowed">
                      Consigli <FontAwesomeIcon icon={faHourglass} />
                    </div>
                  </li>
                </ul>
              </div>
              <div className="w-full lg:w-4/12 px-4 mt-4 lg:mt-0">
                <span className="block uppercase text-gray-600 text-sm font-semibold mb-2">
                  Altre Risorse
                </span>
                <ul className="list-unstyled">
                  <li>
                    <div
                      className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm cursor-pointer"
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                    >
                      Torna in Cima
                    </div>
                  </li>
                  <li>
                    <a
                      className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                      href="https://www.cookiesandyou.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Cookies Policy
                    </a>
                  </li>
                  <li>
                    <Link
                      to={RoutesDef.LOGIN}
                      className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                    >
                      Area Riservata
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-400" />

        <div className="flex flex-col md:flex-row items-center justify-center md:justify-between">
          <div className="md:mx-auto px-4 text-center md:text-left">
            {" "}
            <div className="text-sm text-gray-600 font-semibold py-1 lg:ml-28">
              Copyright © {new Date().getFullYear()} I Razzi Store Modena.
            </div>
          </div>
          <div className="flex flex-col md:flex-row items-center justify-center md:justify-between">
            <div className="text-sm text-gray-600 font-semibold py-1">
              Developed by{" "}
              <a
                href="https://www.tommasoceredi.it"
                target="_blank"
                rel="noreferrer"
                className="text-gray-600 hover:text-gray-900"
              >
                tommasoceredi.it
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
